import React, { Component } from "react";
import Navbar from './../components/Navbar'
import './../assets/css/dashboard.css'
import { Button, Label } from 'reactstrap';
import axios from 'axios';
import QrReader from 'react-qr-reader';
import { BASE_URL } from './../config';
import BoxIdView from './BoxIdView';
import { history } from './../index';
import NotificationAlert from 'react-notification-alert';

const styles = ({
  backgroundColor: "#fff",
  fontSize: "15px",
  borderRadius: "8px",
  marginRight: "10px",
  marginLeft: "10px",
  padding: "5px 5px",
  textAlign: "left",
});
class Dashboard extends Component {
  state = {
    deviceId: '',
    isStarted: false,
    screen: '',
    slotNo: '',
    interval: undefined,
    ejected: false
  }

  componentWillMount() {
    let userId = sessionStorage.getItem('plugoUserId')
    let token = sessionStorage.getItem('plugoAuthKey')
    let mobileNumber = sessionStorage.getItem('mobile')
    let orderId = sessionStorage.getItem('plugoOrderId')

    if (!userId || !mobileNumber || !token) {
      history.push('/')
    }

    axios.get(`${BASE_URL}/rentals/unused-rentals`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then((res) => {
        this.setState({
          pgId: res.data.pgId
        })

        if (res.data) {
          if (res.data['pgId'] && res.data['pickedUp'] === false) {
            sessionStorage.setItem('razorpayId', res.data['pgId']);
            history.push('/scan-qr-code')
          }
          else if (res.data['pgId'] && res.data['pickedUp'] === true) {
            if (!orderId) {
              history.push('/dashboard')
            }
          }
          else {
            if (!orderId) {
              history.push('/dashboard')
            }
          }
        }
        else {
          if (!orderId) {
            history.push('/dashboard')
          }
        }
      });
  }


  handleScreen = (screen) => {
    this.setState({
      screen: screen
    })
  }

  updateBoxId = (boxId) => {
    this.setState({
      deviceId: boxId,
    })
    this.handleRental(boxId)
  }

  handleRental = async (boxId) => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');
    const razorpayId = sessionStorage.getItem("razorpayId");

    axios.post(`${BASE_URL}/users/${userId}/box/${boxId}/rentals`, {
      pgId: razorpayId
    }, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    }).then(rentalResponse => {
      this.setState({
        screen: "rented",
        slotNo: rentalResponse.data.ejectedSlot
      });
      const rentId = rentalResponse.data['id']

      let interval = setInterval(() => {
        this.ejected(BASE_URL, rentId, token, userId)
      }, 2000);
      this.setState({ interval: interval })
    }).catch(error => {
      const options = {
        place: 'tr',
        message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
        type: 'danger',
        icon: 'fas fa-exclamation-triangle',
        autoDismiss: 4
      }
      if (this.notification.state.notifyTR.length < 1) {
        this.notification.notificationAlert(options)
      }
    })
  }

  handleScan = (data) => {
    if (data && typeof (data) === "string") {
      let boxId = data.substr(data.indexOf("=") + 1)
      this.setState({
        deviceId: boxId,
        screen: '',
        ejected: false,
      });
      this.handleRental(boxId)
    }
  }

  ejected = async (BASE_URL, rentId, token, userId) => {
    let pickupResponse = await axios.get(`${BASE_URL}/users/rentals/` + rentId, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })

    if (pickupResponse.data.startTime > 0) {
      clearInterval(this.state.interval)
      this.setState({
        isStarted: true
      })
    }
    if (pickupResponse.data.ejected) {
      if (this.state.ejected === false) {
        setTimeout(() => {
          clearInterval(this.state.interval)
          if (!this.state.isStarted) {
            this.setState({ screen: "notRented" })
          }
        }, 15000)
      }
      this.setState({ ejected: true })

    }
  }


  handleError = err => {
    console.log('aa :', err)
  }

  render() {
    return (
      <div className="dashboard-main">
        <NotificationAlert ref={(c) => { this.notification = c }} />
        <Navbar {...this.props} />
        {this.getScreens()}
      </div>
    )
  }

  getScreens = () => {
    switch (this.state.screen) {
      case "scan": return this.renderScanner()
      case "manual": return <BoxIdView updateBoxId={this.updateBoxId}></BoxIdView>
      case "rented": return this.renderRentedView()
      case "notRented": return this.renderLockScreen()
      default: return this.renderHome()
    }
  }

  renderHome = () => {
    return (
      <div style={{ textAlign: "center", fontSize: "20px" }} >
        <h2 style={{ color: "#00D06A", paddingTop: "5px" }}>Payment Successful</h2>
        <span></span>
        <span></span>
        <div>
          <img src="./scanIcon.png" alt="Logo" style={{ width: "60%", border: "0.2px solid #00D06A", borderRadius: "50%", padding: "4px", marginTop: "40px" }} />
        </div>
        <br />
        <br />
        <Label>Please Scan QR Code on Dispenser</Label>
        <span></span>
        <br />
        <br />
        <div>
          <Button
            color="success"
            onClick={() => { this.setState({ screen: "scan" }) }}
            size="lg"
            style={{
              marginTop: "20px",
              width: "60%",
              borderRadius: "20px"
            }}
          >
            Scan QR Code &nbsp;<i className="fa fa-qrcode"></i></Button>
        </div>
      </div>
    )
  }
  renderLockScreen = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img src="/pick-pwerbank.png" alt="logo" style={{ width: "50%", border: "0.2px solid #00D06A", marginTop: "10%", borderRadius: "50%" }} />
        <br />
        <br />
        <br />
        <h6 style={{ padding: "5px 20px", color: "red", textAlign: "center" }}>Looks like the powerbank was not picked up in time. Please try again!</h6>
        <Button
          color="success"
          onClick={() => { this.setState({ screen: "scan" }) }}
          size="lg"
          style={{
            marginTop: "20px",
            borderRadius: "20px"
          }}
        >Please Scan Again</Button>
      </div>)

  }

  renderScanner = () => {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          <h5>or</h5>
          <Button
            color="success"
            onClick={() => { this.setState({ screen: "manual" }) }}
            style={{
              width: "60%",
              borderRadius: "20px"
            }}
          >
            Enter Code Manually </Button>
        </div></div>)
  }
  renderRentedView = () => {
    return (
      <div style={{ textAlign: "center", fontSize: "20px", marginTop: "20px" }}>
        {!this.state.isStarted ? this.renderRentNotStart() : this.renderRentStarted()}
      </div>
    )
  }

  renderRentNotStart = () => {
    return (
      <div>
        <img src="/pick-pwerbank.png" alt="logo" style={{ width: "50%", border: "0.2px solid #00D06A", borderRadius: "50%" }} />
        <br />
        <br />
        <br />
        <h5><p>Pick your Power Bank from</p>
          <p style={{ display: "inline" }}>slot no:</p> <span style={{ color: "#00D06A", }}>{this.state.slotNo + 1} </span></h5>
      </div>
    )
  }

  renderRentStarted = () => {
    sessionStorage.removeItem('plugoAuthKey');
    sessionStorage.removeItem('plugoUserId');
    sessionStorage.removeItem("razorpayId");
    sessionStorage.removeItem('mobile');
    sessionStorage.removeItem('plugoOrderId')
    return (
      <div>
        <div>
          <h2 style={{ color: "#00D06A" }}>Successfully Rented</h2>
          <br />
          <div>
            <img src="/success.png" alt="Logo" style={{ width: "50%", border: "0.2px solid #00D06A", borderRadius: "50%", padding: "4px", marginTop: "10px" }} />
          </div>
          <br />
          <div style={styles}>
            <div style={{ display: "flex" }} >
              <div><i className="fa fa-check" style={{ color: "#00D06A" }} /></div>
              <div style={{ marginLeft: "3%" }}><span>Power bank will be locked after 24 hours and cannot be charged at home</span></div>
            </div>
            <div style={{ display: "flex" }} >
              <div><i className="fa fa-check" style={{ color: "#00D06A" }} /></div>
              <div style={{ marginLeft: "3%" }}><span>Please drop the power bank in a Plugo dispenser before you leave</span></div>
            </div>
          </div>
        </div>
        <h3 style={{ color: "#00D06A", margin: "10% 0" }}>Thank you</h3>
        <div
          style={{ backgroundColor: "#00D06A",color:"#fff", borderRadius: 0, position: "fixed", bottom: "0", width: "100%",height:"40px" }}
        >
          Visit <a style={{ color: "#fff" }} href="https://plugo.io" >https://plugo.io</a> for more info
          </div>
      </div>
    )
  }

}

export default Dashboard;