import React, { Component } from "react";
import { Navbar, NavbarBrand } from 'reactstrap';
import './../assets/css/dashboard.css';

class AppNavbar extends Component {
  state = {
    loading: false
  }

  render() {
    const { offline } = this.props
    return (
      <div className={offline ? "top-navbar" : ""}>
        {this.state.loading ? <div className='loading-spinner'></div> : ''}
        <Navbar style={{
          backgroundColor: '#ffffff', WebkitBoxShadow: '0 8px 6px -6px #999',
          MozBoxShadow: '0 8px 6px -6px #999',
          boxShadow: '0 8px 6px -6px #999'
        }} light expand="sm" fixed='true'>
          <NavbarBrand style={{marginRight:"0px"}}>
            <img src="./Plugo-Logo-New-White-Bkgrd.png" style={{ width: '25%', float:"right",background:'linear-gradient(to bottom, #aae7f6 0%, #ff99cc 177%)' }} alt="Logo" />
          </NavbarBrand>
        </Navbar>
      </div>
    )
  }
}

/* 
const mapStateToProps = (state, props) => ({
  offline: state.settings.isOffline
}) 
*/

export default(AppNavbar)