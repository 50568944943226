import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from "history";
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router-dom";
import './index.css';
import Dashboard from './views/Dashboard';
import Login from './views/Login.jsx';
import Qr from './views/Qr.jsx';
import BoxIdView from './views/BoxIdView.jsx';
import ChargingCode from './views/ChargingCode';

export const history = createBrowserHistory();

ReactDOM.render(
   <Router history={history}>
      <Switch>
         <Route
            path="/dashboard"
            component={Dashboard}
            exact={true}
         />
         <Route
            path="/"
            component={Login}
            exact={true}
         />
         <Route
            path="/scan-qr-code"
            component={Qr}
            exact={true}
         />
         <Route
            path="/box-id"
            component={BoxIdView}
            exact={true}
         />
         <Route
            path="/passcode"
            component={ChargingCode}
            exact={true}
         />
         <Route
            path="/rent-powerbank/:deviceId"
            component={Login}
            exact={true}
         />
      </Switch>
   </Router>, document.getElementById('root'));