import React, { Component } from "react";
import './../assets/css/dashboard.css'
import { Button, Input, InputGroup } from 'reactstrap';

class Dashboard extends Component {
  state = {
    boxId: ''
  }
  change = (event) => {
    this.setState({ boxId: event.target.value })
  }

  handleSubmit = () => {
    this.props.updateBoxId(this.state.boxId)
  }
  verifySixCharID = (value) => {
    if (value && value !== null) {
      var sixCharIDRex = /^[a-zA-z]{6}$/;
      if (sixCharIDRex.test(value)) {
        return true;
      }
      return false;
    }
  }

  render() {
    return (
      <div className="dashboard-main">
        <div>
          <label style={{ paddingLeft: "3%", paddingTop: "4%" }}>Enter Code Manually</label>
        </div>
        <div>
          <InputGroup style={{ padding: "3%" }}>
            <Input
              name="Plugo Box Id"
              autoComplete="off"
              onChange={this.change}
              placeholder="Enter Plugo Box Id"
              style={{ border: '1px solid #ffffff' }}
              autoCapitalize="off"
            />
          </InputGroup>
        </div>
        <Button
          onClick={this.handleSubmit}
          block
          color="success"
          size="lg"
          style={{ position: "fixed", bottom: "0", width: "100%" }}
          disabled={!this.verifySixCharID(this.state.boxId)}

        >
          Submit
        </Button>
      </div>
    )
  }

}

export default Dashboard;