import axios from 'axios';
import React, { Component } from "react";
import { Button, Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody } from 'reactstrap';
import './../assets/css/login.css';
import { history } from './../index';
import { BASE_URL } from './../config';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class Login extends Component {
  state = {
    type: 'InstantAppUser',
    mobileNo: '',
    loading: false,
    deviceId: '',
    pgId: '',
    showMedia: false,
    mediaType: '', 
    mediaUrl: '', 
    mediaDuration: 0, 
    isModal: true, 
    remainingTime: 0,
    enableButton: false,
    hasPaid: false,
    isLoggedIn: false,
    selectedTariff: 'hour',
    isShowAdAndCodeButtonClicked: false,
    isPaymentButtonVisible: true,
    isShowAdAndCodeButtonVisible : true
  };

  countdown = null; 

  componentDidMount() {
    const { match: { params } } = this.props;
    const param = new URLSearchParams(window.location.search);
    const deviceId = param.get('deviceId');
    localStorage.setItem('deviceId', deviceId);

    this.setState({ deviceId });

    let userId = sessionStorage.getItem('plugoUserId');
    let mobileNumber = sessionStorage.getItem('mobile');
    let authKey = sessionStorage.getItem('plugoAuthKey');
   // let sessionStatus = sessionStorage.getItem('sessionStatus');

    if (userId && mobileNumber && authKey) {
      history.push('/');
    }
  }

  componentWillUnmount() {
    clearInterval(this.countdown); 
    let userId = sessionStorage.getItem('plugoUserId')
    let mobileNumber = sessionStorage.getItem('mobile')
    let authKey = sessionStorage.getItem('plugoAuthKey')

    if (!userId || !mobileNumber || !authKey) {
      history.push('/')
    }

    const token = authKey
    axios.get(`${BASE_URL}/rentals/unused-rentals`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then((res) => {

        this.setState({ loading: false })
        if (res.data) {
          if (res.data['pgId'] && res.data['pickedUp'] === false) {
            sessionStorage.setItem('razorpayId', res.data['pgId']);
          }
          else {
            history.push('/')
          }
        }
        else {
          
        }
      }).catch(error => {
        this.setState({ loading: false })
        const options = {
          place: 'tr',
          message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
          type: 'danger',
          icon: 'fas fa-exclamation-triangle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      })
  }

  handleTariffChange = (tariff) => {
    this.setState({
      selectedTariff: tariff,
    });
  }

  verifyMobileNo = value => {
    var mobileNoRex = /^\d{10}$/;
    return mobileNoRex.test(value);
  }

  change = (event) => {
    this.setState({ mobileNo: event.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const deviceId = localStorage.getItem('deviceId');
    axios.post(`${BASE_URL}/session/${deviceId}`, {
      mobile: '+91' + this.state.mobileNo,
      type: this.state.type
    }).then((response) => {
      sessionStorage.setItem('plugoAuthKey', response.data.value);
      sessionStorage.setItem('plugoUserId', response.data.user.id);
      sessionStorage.setItem('mobile', response.data.user.mobile);
     // sessionStorage.setItem('sessionStatus', response.data.activeSessionStatus);
      this.setState({ mobileNo: '', isLoggedIn: true });

      const token = sessionStorage.getItem('plugoAuthKey');
      const userId = sessionStorage.getItem('plugoUserId');
      const deviceId = localStorage.getItem('deviceId');
      var sessionStatus = localStorage.getItem('sessionStatus');
         axios(`${BASE_URL}/cable/${userId}/active-session`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-USER-ID": userId
          }
        }).then((response) => {
          if (response.data.sessionActive && response.data.passCode) {
            localStorage.setItem('passcode', response.data.passCode);
            return history.push('/passcode');
          } else {
            this.showAd();
          }
        }).catch(error => {
          console.error('Error fetching active session:', error);
        });
    });
  }

  showAd = () => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');

    axios.get(`${BASE_URL}/ad/url`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
    .then(response => {
      const mediaUrl = response.data.adUrl;
      const mediaType = response.data.mediaType;
      localStorage.setItem('mediaUrl', mediaUrl);
      localStorage.setItem('mediaType', mediaType);
      this.setState({mediaType, mediaUrl});
    })
    .catch(error => {
      console.error('Error fetching url:', error);
    });
  }

  showAdAndCodeButton = () => {
    const showMediaStatus = this.showMedia();
    this.setState({isShowAdAndCodeButtonClicked: true, showMediaStatus,isPaymentButtonVisible: false, isShowAdAndCodeButtonVisible: false});
  }

  showMedia = () => {
    const mediaType = localStorage.getItem('mediaType'); 
    const mediaDuration = mediaType === 'video' ? 15 : 20;
    this.setState({mediaType, mediaDuration, remainingTime: mediaDuration });
    const endTime = Date.now() + mediaDuration * 1000;
    this.countdown = setInterval(() => {
      const remainingTime = Math.round((endTime - Date.now()) / 1000);
      if (remainingTime <= 0) {
        clearInterval(this.countdown);
        this.setState({ remainingTime: 0, enableButton: true });
      } else {
        this.setState({ remainingTime });
      }
    }, 1000);
  }

  handleGetCodeClick = () => {
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');
    const deviceId = localStorage.getItem('deviceId');

    axios.get(`${BASE_URL}/cable/get-code/${deviceId}/${userId}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
    .then(response => {
      const passCode = response.data.passCode;
      this.setState({ passCode });
      localStorage.setItem('passCode', passCode);
      history.push('/passcode');
    })
    .catch(error => {
      console.error('Error fetching code:', error);
      history.push('/');
    });
  }

  handlePaymentClick = async (e) => {
      this.setState({
        mainloading: true
      })
      const selectedTariffValue = this.state.selectedTariff;
      const token = sessionStorage.getItem('plugoAuthKey');
      const userId = sessionStorage.getItem('plugoUserId');
      const mobile = sessionStorage.getItem('mobile');
      if ((localStorage.getItem('deviceId')) != 'null') {
        try {
          const { data } = await axios(`${BASE_URL}/cable/${userId}/active-session`, {
            headers: {
              "Authorization": `Bearer ${token}`,
              "X-USER-ID": userId
            }
          });
          if (data.sessionActive && data.passCode) {
            localStorage.setItem('passcode', data.passCode);
            return history.push('/passcode');
          }
        } catch (error) {
          console.error(error.response.data)
        }
        axios.post(`${BASE_URL}/orders?duration=2`, {
          "userId": userId,
          "mobile": mobile,
          "duration": 2,
  
  
        }, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-USER-ID": userId
          }
        })
          .then((response) => {
            sessionStorage.setItem('plugoOrderId', response.data['plugo_order_id']);
            var options = {
              "key": response.data['key'],
              "amount": response.data['amount'],
              "currency": response.data['currency'],
              "name": response.data['name'],
              "image": response.data['image'],
              "description": response.data['description'],
              "order_id": response.data['order_id'],
              "handler": function (response) {
                const razorPayId = response['razorpay_payment_id'];
                const razorOrderId = response['razorpay_order_id'];
                const razorSignature = response['razorpay_signature'];
  
                const token = sessionStorage.getItem('plugoAuthKey');
                const userId = sessionStorage.getItem('plugoUserId');
  
  
                const verifyOrder = {
                  razorpay_order_id: razorOrderId,
                  razorpay_payment_id: razorPayId,
                  razorpay_signature: razorSignature
                }
                sessionStorage.setItem('razorpayId', razorPayId);
  
                // UPDATE BOOKING STATUS TO USER DECLINED
                axios.post(`${BASE_URL}/orders/verify`, verifyOrder, {
                  headers: {
                    "Authorization": `Bearer ${token}`,
                    "X-USER-ID": userId
                  }
                })
                  .then((response) => {
                    sessionStorage.setItem('paymentStatus', response.data.currentStatus);
                    axios.get(`${BASE_URL}/cable/${localStorage.getItem("deviceId")}/2/${userId}`
                    , {
                      headers: {
                        "Authorization": `Bearer ${token}`,
                        "X-USER-ID": userId
                      }
                    })
                      .then((response) => {
                        console.log("reseeeeeeeponse", response)
                        localStorage.setItem('passcode', response.data.passCode);
  
                        history.push('/passcode')
                        this.setState({
                          mainloading: false
                        });
  
                      });
                  });
  
              },
              "prefill": {
                "email": "",
                "contact": response.data['prefill_contact']
              },
              "notes": {
                "userId": response.data['notes_user_id'],
                "paymentType": response.data['notes_payment_type'],
                "type": response.data['notes_type'],
                "contact": response.data['notes_contact']
              },
              "theme": {
                "color": response.data['theme_color']
              },
              'modal': {
                'backdropclose': false,
                'escape': false,
                'ondismiss': function (event) {
  
                  // UPDATE BOOKING STATUS TO USER DECLINED
                  const plugoOrderId = sessionStorage.getItem('plugoOrderId')
                  axios.put(`${BASE_URL}/orders/` + plugoOrderId, {
                    currentStatus: 'user_cancelled'
                  }, {
                    headers: {
                      "Authorization": `Bearer ${token}`,
                      "X-USER-ID": userId
                    }
                  })
                    .then((response) => {
  
                    });
                }
              }
            };
            var rzp1 = new window.Razorpay(options);
            this.setState({
              loading: false,
              rzp1: rzp1
            });
            this.state.rzp1.open();
  
          }).catch(error => {
            this.setState({ loading: false })
            const options = {
              place: 'tr',
              message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
              type: 'danger',
              icon: 'fas fa-exclamation-triangle',
              autoDismiss: 4
            }
            if (this.notification && this.notification.state.notifyTR.length < 1) {
              this.notification.notificationAlert(options)
            }
          })
      } else {
        this.setState({ loading: false })
        const options = {
          place: 'tr',
          message: 'Please scan code again',
          type: 'danger',
          icon: 'fas fa-exclamation-triangle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      }
  
    
  }

  renderMedia = () => {
    const {remainingTime, enableButton } = this.state;
    return (
      <div style={{ textAlign: 'center', color: 'white' }}>
        <div style={{ fontSize: '19px',color: 'black' }}>
          Getting code in {remainingTime} seconds...
        </div>
        <Button
          color="success"
          size="lg"
          disabled={!enableButton}
          onClick={this.handleGetCodeClick}
          style={{ marginTop: '5px' }}
        >
          Get Code
        </Button>
      </div>
    );
  }

  render() {
    const { mediaType, mediaUrl,isLoggedIn,isShowAdAndCodeButtonClicked,isPaymentButtonVisible,isShowAdAndCodeButtonVisible } = this.state;
    const tariffAmount = 5;

    return (
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="5" md="6">
            {!isLoggedIn ? (
              <Form className="form" onSubmit={e => {
                if (this.verifyMobileNo(this.state.mobileNo)) {
                  this.handleSubmit(e);
                } else {
                  e.preventDefault();
                }
              }}>
                <div className="logo-container">
                  <div style={{ textAlign: "center", marginTop: "10%" }}>
                    <img src='./Plugo-Logo-New-Blue.png' style={{ height: "20%" }} alt="Logo" />
                    <div style={{ marginTop: "20px" }}>
                      <h4 style={{ padding: "4px 4px" }}>SCAN AND CHARGE</h4>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='login-form'>
                    <Card className='login-card'>
                      <div>
                        <div>
                          <CardBody>
                            <FormGroup>
                              <label>
                                Mobile Number *
                              </label>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{ border: '1px solid #E9ECEF' }}>
                                    <i className="fas fa-mobile-alt" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  name="mobileNumber"
                                  autoComplete="off"
                                  type="number"
                                  onChange={this.change}
                                  autoFocus
                                  placeholder="Enter your mobile number"
                                  style={{ border: '1px solid #ffffff' }}
                                  autoCapitalize="off"
                                />
                              </InputGroup>
                            </FormGroup>
                          </CardBody>
                          <CardFooter>
                            <FormGroup>
                              <Button
                                type="submit"
                                block
                                color="success"
                                size="lg"
                                disabled={!this.verifyMobileNo(this.state.mobileNo)}
                              >
                                Login
                              </Button>
                            </FormGroup>
                          </CardFooter>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Form>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <div className="logo-container">
                  <div style={{ textAlign: "center", marginTop: "10%" }}>
                    <img src='./Plugo-Logo-New-Blue.png' style={{ height: "20%" }} alt="Logo" />
                  </div>
                </div>
                <div style={{marginBottom: '10px'}}>
                {mediaType === 'video' ? (
          <video src={mediaUrl} autoPlay controls style={{ width: '100%', height: 'auto' }} />
        ) : (
          <img src={mediaUrl} alt="Media" style={{ width: '100%', height: 'auto' }} />
        )}
                </div>
                {isPaymentButtonVisible && (
                  <Button
                    color="success"
                    size="lg"
                    onClick={this.handlePaymentClick}
                    style={{ marginTop: "10px" }}
                  >
                    Pay ₹{tariffAmount} to skip ad
                  </Button>
                )}
                <br />
                {isShowAdAndCodeButtonVisible &&
                    <a href="#" onClick={(e) => { e.preventDefault(); this.showAdAndCodeButton(); }}>
                    Watch ad to get code
                  </a>
                }
                <br/>
                {isShowAdAndCodeButtonClicked && (
                  <div style={{ marginTop: '1px' }}>
                    {this.renderMedia()}
                    </div>
                )}
              </div>
            )}
          </Col>
        </Container>
      </div>
    );
  }
}

export default Login;
