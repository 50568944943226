import React, { Component } from "react";
import Navbar from './../components/Navbar';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BASE_URL } from './../config';
import axios from 'axios';
import NotificationAlert from 'react-notification-alert';
const styles = ({
  backgroundColor: "#fff",
  fontSize: "15px",
  borderRadius: "8px",
  marginRight: "10px",
  marginLeft: "10px",
  padding: "10px 5px",
  textAlign: "center",
  margin: "5px"
});
class ChargingCode extends Component {
  componentDidMount() {
    let userId = sessionStorage.getItem('plugoUserId')
    let mobileNumber = sessionStorage.getItem('mobile')
    let authKey = sessionStorage.getItem('plugoAuthKey')
    // const param = new URLSearchParams(window.location.search);
    // const deviceId = param.get('deviceId');
    // localStorage.setItem('deviceId', deviceId);
    // axios.get(`${BASE_URL}/cable/get-code/${deviceId}`)
    //   .then(response => {
    //     const passCode = response.data.passCode;
    //     const userId = response.data.userId;
    //     this.setState({
    //       passCode: passCode,
    //       userId: userId
    //     });
    //     localStorage.setItem('passCode', passCode);
    //     localStorage.setItem('userId', userId);
    //     console.log('Code fetched successfully');
    //   })
    //   .catch(error => {
    //     console.error('Error fetching code:', error);
    //   });
  }
  state = {
    passCode: localStorage.getItem('passCode'),
    modalOpen: false
  }
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  }
  handleYes = () => {
    const confirmed = window.confirm("Do you want to get a new code?");
    if (confirmed) {
      // const token = sessionStorage.getItem('plugoAuthKey');
      // const storedUserId = localStorage.getItem('userId');
      let userId = sessionStorage.getItem('plugoUserId')
    let token = sessionStorage.getItem('plugoAuthKey')
      axios.get(`${BASE_URL}/cable/${userId}/check-active-session`, { headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
  }
      })
        .then((response) => {
          if (response.data.sessionActive) {
            localStorage.setItem('passCode', response.data.passCode);
            this.setState({ passCode: response.data.passCode });
          }
        })
        .catch(error => {
          console.log(error.response);
          const options = {
            place: 'tr',
            message: `  ${error.response ? error.response.data : 'Something went wrong'}`,
            type: 'danger',
            icon: 'fas fa-exclamation-triangle',
            autoDismiss: 4
          };
          if (this.notification && this.notification.state.notifyTR.length < 1) {
            this.notification.notificationAlert(options);
          }
        });
    }
  }
  render() {
    return (
      <>
        <Navbar {...this.props} />
        <NotificationAlert ref={(c) => { this.notification = c }} />
        <div >
          <h5>&nbsp; </h5>
          <h5>&nbsp; </h5>
        </div>
        <div style={styles} >
          <h5>Passcode</h5>
          <h1 style={{ marginBottom: 0, color: "#64B352" }} id="passCode"> <b>{this.state.passCode}</b></h1>
        </div>
        <div style={{ textAlign: "center" }}>
          <div style={{ padding: "2px 4px" }}>
            <img src="./panel-keypad.png" alt="Logo" style={{ width: "25%", border: "1px solid #00D06A", borderRadius: "50%", padding: "4px" }} />
          </div>
          <div style={styles}>
            <h5>Enter Passcode on cable keypad</h5>
          </div>
          <div style={styles}>
            <h5>Once device light turns green, you can charge</h5>
          </div>
          <div style={styles}>
            <Button
              id="rzp-button1"
              onClick={this.handleYes}
              block
              color="newcode"
              size="lg"
              style={{
                border: 0,
                borderRadius: 0,
                bottom: 0,
              }}
            >  Click to Get New Code
            </Button>
          </div>
        </div>
        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Confirmation</ModalHeader>
          <ModalBody>
            Do you want to get a new code?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleYes}>Yes</Button>
            <Button color="secondary" onClick={this.toggleModal}>No</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
export default ChargingCode;