import React, { Component } from "react";
import Navbar from './../components/Navbar'
import './../assets/css/dashboard.css'
import { Button, ListGroup, ListGroupItem, Badge, Spinner, Label, Input } from 'reactstrap';
import axios from 'axios';
import { history } from './../index';
import { BASE_URL } from './../config';
import NotificationAlert from 'react-notification-alert';
import { isNull } from "lodash";


const styles = ({
  backgroundColor: "#fff",
  fontSize: "15px",
  borderRadius: "8px",
  marginRight: "10px",
  marginLeft: "10px",
  padding: "10px 5px",
  textAlign: "center",
  //  color: "#33433d"
  //  textAlign: "left"
});

const radioStyles=({
  display:"flex",
  flexDirection:"coloum",
  gap:"40px",
  justifyContent:"center",
  allignItem:"center"

});
class Dashboard extends Component {
  state = {
    paymentStatus: '',
    loading: true,
    mainloading: false,
    selectedTariff: 'hour', // Added state to track the selected tariff

  }

  componentWillMount() {
    let userId = sessionStorage.getItem('plugoUserId')
    let mobileNumber = sessionStorage.getItem('mobile')
    let authKey = sessionStorage.getItem('plugoAuthKey')

    if (!userId || !mobileNumber || !authKey) {
      history.push('/')
    }

    const token = authKey
    axios.get(`${BASE_URL}/rentals/unused-rentals`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "X-USER-ID": userId
      }
    })
      .then((res) => {

        this.setState({ loading: false })
        if (res.data) {
          if (res.data['pgId'] && res.data['pickedUp'] === false) {
            sessionStorage.setItem('razorpayId', res.data['pgId']);
            history.push('/dashboard')
          }
          else {
            history.push('/dashboard')
          }
        }
        else {
          history.push('/dashboard')
        }
      }).catch(error => {
        this.setState({ loading: false })
        const options = {
          place: 'tr',
          message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
          type: 'danger',
          icon: 'fas fa-exclamation-triangle',
          autoDismiss: 4
        }
        if (this.notification && this.notification.state.notifyTR.length < 1) {
          this.notification.notificationAlert(options)
        }
      })
  }

  handleTariffChange = (tariff) => {
    this.setState({
      selectedTariff: tariff,
    });
  }

  handleSubmit = async (e) => {
    this.setState({
      mainloading: true
    })
    const selectedTariffValue = this.state.selectedTariff;
    const token = sessionStorage.getItem('plugoAuthKey');
    const userId = sessionStorage.getItem('plugoUserId');
    const mobile = sessionStorage.getItem('mobile');
    if ((localStorage.getItem('deviceId')) != 'null') {
      try {
        const { data } = await axios(`${BASE_URL}/cable/${userId}/active-session`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-USER-ID": userId
          }
        });
        if (data.sessionActive && data.passCode) {
          localStorage.setItem('passcode', data.passCode);
          return history.push('/passcode');
        }
      } catch (error) {
        console.error(error.response.data)
      }
      axios.post(`${BASE_URL}/orders?duration=2`, {
        "userId": userId,
        "mobile": mobile,
        "duration": 2,


      }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "X-USER-ID": userId
        }
      })
        .then((response) => {
          sessionStorage.setItem('plugoOrderId', response.data['plugo_order_id']);
          var options = {
            "key": response.data['key'],
            "amount": response.data['amount'],
            "currency": response.data['currency'],
            "name": response.data['name'],
            "image": response.data['image'],
            "description": response.data['description'],
            "order_id": response.data['order_id'],
            "handler": function (response) {
              const razorPayId = response['razorpay_payment_id'];
              const razorOrderId = response['razorpay_order_id'];
              const razorSignature = response['razorpay_signature'];

              const token = sessionStorage.getItem('plugoAuthKey');
              const userId = sessionStorage.getItem('plugoUserId');


              const verifyOrder = {
                razorpay_order_id: razorOrderId,
                razorpay_payment_id: razorPayId,
                razorpay_signature: razorSignature
              }
              sessionStorage.setItem('razorpayId', razorPayId);

              // UPDATE BOOKING STATUS TO USER DECLINED
              axios.post(`${BASE_URL}/orders/verify`, verifyOrder, {
                headers: {
                  "Authorization": `Bearer ${token}`,
                  "X-USER-ID": userId
                }
              })
                .then((response) => {
                  sessionStorage.setItem('paymentStatus', response.data.currentStatus);
                  axios.get(`${BASE_URL}/cable/${localStorage.getItem("deviceId")}/2/${userId}`
                  , {
                    headers: {
                      "Authorization": `Bearer ${token}`,
                      "X-USER-ID": userId
                    }
                  })
                    .then((response) => {
                      console.log("reseeeeeeeponse", response)
                      localStorage.setItem('passcode', response.data.passCode);

                      history.push('/passcode')
                      this.setState({
                        mainloading: false
                      });

                    });
                });

            },
            "prefill": {
              "email": "",
              "contact": response.data['prefill_contact']
            },
            "notes": {
              "userId": response.data['notes_user_id'],
              "paymentType": response.data['notes_payment_type'],
              "type": response.data['notes_type'],
              "contact": response.data['notes_contact']
            },
            "theme": {
              "color": response.data['theme_color']
            },
            'modal': {
              'backdropclose': false,
              'escape': false,
              'ondismiss': function (event) {

                // UPDATE BOOKING STATUS TO USER DECLINED
                const plugoOrderId = sessionStorage.getItem('plugoOrderId')
                axios.put(`${BASE_URL}/orders/` + plugoOrderId, {
                  currentStatus: 'user_cancelled'
                }, {
                  headers: {
                    "Authorization": `Bearer ${token}`,
                    "X-USER-ID": userId
                  }
                })
                  .then((response) => {

                  });
              }
            }
          };
          var rzp1 = new window.Razorpay(options);
          this.setState({
            loading: false,
            rzp1: rzp1
          });
          this.state.rzp1.open();

        }).catch(error => {
          this.setState({ loading: false })
          const options = {
            place: 'tr',
            message: `  ${error.response ? error.response.data.message : 'Something went wrong'}`,
            type: 'danger',
            icon: 'fas fa-exclamation-triangle',
            autoDismiss: 4
          }
          if (this.notification && this.notification.state.notifyTR.length < 1) {
            this.notification.notificationAlert(options)
          }
        })
    } else {
      this.setState({ loading: false })
      const options = {
        place: 'tr',
        message: 'Please scan code again',
        type: 'danger',
        icon: 'fas fa-exclamation-triangle',
        autoDismiss: 4
      }
      if (this.notification && this.notification.state.notifyTR.length < 1) {
        this.notification.notificationAlert(options)
      }
    }

  }

  render() {
    const tariffAmount = 5; // Calculate the tariff amount based on selection

    return (
      <React.Fragment>
        {this.state.mainloading ?
          <div className="dashboard-main">
            <Navbar {...this.props} />
            <NotificationAlert ref={(c) => { this.notification = c }} />
            <div style={{ textAlign: "center", paddingTop: "100px" }}>
              <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
              <h1>Loading...</h1>
            </div>
          </div> :
          <div className="dashboard-main">
            <Navbar {...this.props} />
            <NotificationAlert ref={(c) => { this.notification = c }} />
            {
              this.state.loading ?
                <div className='loading-spinner'></div>
                :
                <div style={{ textAlign: "center" }}>


                  <div style={styles}>
                    <h5>Tariff</h5>
                    <div style={radioStyles}>
                    <Label check style={{fontSize:"20px"}}> 
                      <Input
                        type="radio"
                        name="tariff"
                        checked={this.state.selectedTariff === 'hour'}
                        onChange={() => this.handleTariffChange('hour')}
                      />
                      {' '}
                      ₹5 for 1 hour
                    </Label>
                    </div>
                   
                  </div>

                  <div style={{ padding: "4px 4px" }}>
                    <img src="./panel-close.png" alt="Logo" style={{ width: "26%", border: "1px solid #00D06A", borderRadius: "50%", padding: "4px" }} />
                  </div>
                  <div style={styles}>
                    <h5>How To  Use</h5>
                    <ListGroup>
                      <ListGroupItem className="justify-content-between"> <Badge pill>1</Badge> Pay to get a 5-digit Code</ListGroupItem>
                      <ListGroupItem className="justify-content-between"> <Badge pill>2</Badge> Enter Code on device keypad. The light will turn green</ListGroupItem>
                      <ListGroupItem className="justify-content-between"><Badge pill>3</Badge>  If Code is not recieved after payment, scan QR code again. No money will be charged</ListGroupItem>
                    </ListGroup>
                  </div>

                  <Button
                    id="rzp-button1"
                    onClick={this.handleSubmit}
                    block
                    color="success"
                    size="lg"
                    style={{
                      position: "fixed",
                      border: 0,
                      borderRadius: 0,
                      bottom: 0
                    }}
                  >
                    PAY ₹{tariffAmount}
                  </Button>
                </div>
            }
            {/* <Footer /> */}
          </div>
        }

      </React.Fragment>
    )
  }

}

export default Dashboard;